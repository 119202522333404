import React from 'react'
import Sections from '../../components/Sections'
import MainLayout from '../../components/MainLayout'
import TopHero from '../../components/Sections/TopHero'
import Content from '../../components/Sections/Content'
import { get } from 'lodash'

const Page = ({ data, pageContext }) => {
  const isHomepage = pageContext.is_homepage
  let topHeroContent
  let hasTopHeroImage
  let hasTopHeroVideo

  if (!isHomepage) {
    topHeroContent = {
      video: get(data, 'wordpressPage.acf.video.source_url', null),
      mobileImage: get(data, 'wordpressPage.acf.mobile_image.source_url', null),
      image: get(
        data,
        'wordpressPage.acf.image.localFile.childImageSharp.fluid',
        null
      ),
      title: get(data, 'wordpressPage.acf.title', ''),
      paragraph: get(data, 'wordpressPage.acf.paragraph', ''),
    }

    hasTopHeroImage = !!topHeroContent.image
    hasTopHeroVideo = !!topHeroContent.video
  }

  const contentData = get(data, 'wordpressPage', {})
  const sections = get(data, 'wordpressPage.acf.sections_page', [])

  const description = contentData.acf.meta_description ? contentData.acf.meta_description : null
  const image = data.wordpressPage.acf.meta_image?.localFile?.url
  const metaData = {
    'title': contentData?.acf.meta_title,
    'keywords': contentData.acf.keywords,
    'description': description,
    'image': image,
  }

  return (
    <MainLayout
      topSpacing={!isHomepage && (!hasTopHeroImage || !hasTopHeroVideo)}
      pageId={data?.wordpressPage?.wordpress_id}
      metaData={metaData}
    >
      {!isHomepage && (hasTopHeroImage || hasTopHeroVideo) && (
        <TopHero {...topHeroContent} />
      )}
      {contentData.content && <Content {...contentData} />}
      <Sections sections={sections} />
    </MainLayout>
  )
}

export default Page
