import React from 'react'
import styles from './VideoFullWidth.module.scss'
import classNames from 'classnames'

const VideoFullWidth = data => {
  return (
    <section className={classNames('container', styles.container)}>
      <div className={classNames('w-100', styles.videoContainer)}
        dangerouslySetInnerHTML={{ __html: data.url }}
      />
    </section>
  )
}

export default VideoFullWidth
